<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="$scopedSlots['filter'] ? Math.round(cols / 2) : cols" class="pa-0">
        <v-text-field
            outlined dense
            v-model="search"
            label="Search"
            color="primary"
            hide-details
            clearable
            @click:clear="clear"
        >
          <template v-slot:append>
            <v-icon color="primary" @click="searchItem" :disabled="!search" dark>search</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col :cols="cols / 2" class="py-0 px-2" v-if="$scopedSlots['filter']">
        <slot name="filter"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Search',
  props: {
    cols: {type: Number, default: 4}
  },
  data: () => ({
    search: '',
  }),
  methods: {
    clear() {
      this.search = ''
      this.$emit('clear', '');
    },
    searchItem() {
      this.$emit('search', this.search);
    }
  }
}
</script>

<style scoped>
.col-md-4 {
  padding: 12px 0;
}

.col-md-2 {
  padding: 12px 0;
}

.mx-2 {
  margin: 0 0 0 8px !important;
}
</style>