<template>
  <div>
    <v-text-field
        v-model="date"
        outlined
        placeholder="дд.мм.гггг"
        maxlength="10"
        :label="label"
        @input="formatDate($event)"
        @keydown="handleKeyDown($event)"
        :rules="dateRules"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    label: {type: String, default: 'Введите дату'},
    rules: {type: Array},
    value: {type: String, default: ''}
  },
  data() {
    return {
      date: '',
      dateRules: [
        value => {
          if (value === '') {
            return true;
          }
          const regex = /^(\d{2}\.){0,2}\d{0,4}$/;
          return regex.test(value) || 'Некорректный формат даты';
        }
      ]
    }
  },
  watch: {
    date(val) {
      if (val.length === 10) {
        let date = val.split(".")

        let result = date[0] + "." + date[1] + "." + date[2]

        console.log(result)
        this.$emit('update', result)

      }
    },
    value(val) {
      if (!!val && !this.date) {
        let split = val.replace("undefined-undefined-", '').replace('undefined', '').replace('T00:00:00', '').replace(' ', '').replace('\.', '-').replace('\.', '-').split('-')
        val = split[0] + '.' + split[1] + '.' + split[2]
        this.date = val.replace('T00:00:00 ', '').replace(' ', '')
      }
    }
  },
  created() {
    let val = this.value

    let split = val.replace("undefined-undefined-", '').replace('undefined', '').replace('T00:00:00', '').replace(' ', '').replace('\.', '-').replace('\.', '-').split('-')

    if (split.length >= 2) {
      val = split[0] + '.' + split[1] + '.' + split[2]
      this.date = val.replace('T00:00:00 ', '').replace(' ', '')
    }


    if (!!this.rules) {
      this.dateRules = this.dateRules.concat(this.rules)
    }
  },
  methods: {
    formatDate(event) {
      // console.log(event)
      let input = event.replace(/\D/g, '').substring(0, 8);
      let date = '';

      if (input.length === 0) {
        date = '';
      } else if (input.length < 3) {
        if (input > 31) {
          date = 31
        } else {
          date = input;
        }
      } else if (input.length < 5) {
        console.log("2 " + input)
        if (input.substring(2) > 12) {
          date = input.substring(0, 2) + '.12'
        } else {
          date = input.substring(0, 2) + '.' + input.substring(2);
          ;
        }
      } else if (input.length < 9) {
        date = input.substring(0, 2) + '.' + input.substring(2, 4) + '.' + input.substring(4, 8);
      }

      this.date = date;
    },
    handleKeyDown(event) {
      if (event.keyCode !== 8 && event.keyCode !== 46) {
        let input = event.target.value.replace(/\D/g, '').substring(0, 8);
        let date = '';

        if (input.length === 0) {
          date = '';
        } else if (input.length < 3) {
          date = input;
        } else if (input.length < 5) {
          date = input.substring(0, 2) + '.' + input.substring(2);
        } else if (input.length < 9) {
          date = input.substring(0, 2) + '.' + input.substring(2, 4) + '.' + input.substring(4, 8);
        }

        event.target.value = date;
      }
    }
  }
}
</script>